import React from "react";

const Vista = () => {
  return (
    <div className="h-screen">
        {/* Description */}
      <div className="mb-4">
        <p className="text-sm text-gray-600">
        <span className="text-red-500">Vista</span> is an enduring project dedicated to capturing the planet’s natural beauty through a thoughtful lens, with a focus on elegance and simplicity.
        </p>
      </div>
      <iframe
        title="Map"
        src="https://www.openstreetmap.org/export/embed.html"
        style={{ width: "100%", height: "100%" }}
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Vista;
