import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const PhotosLayout = () => {
  return (
    <div className="bg-white min-h-screen flex flex-col sm:flex-row">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="p-8 w-full sm:ml-48">
        <Outlet />
      </div>
    </div>
  );
};

export default PhotosLayout;
