import React, { useState, useEffect } from "react";
import { Heart } from 'lucide-react';


const AnonymousFeatureDocumentation = () => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [loves, setLoves] = useState(0);
  
    const postId = "10306753257183055"
    const API_BASE_URL = "https://post-interactions.aleenaalby20.workers.dev"
    
    useEffect(() => {
      const fetchData = async () => {
        const res = await fetch(`${API_BASE_URL}/get?postId=${postId}`);
        const data = await res.json();
        console.log(data);
        setComments(data.comments);
        setLoves(data.loves);
    }
    fetchData()
    }, [postId, API_BASE_URL])
  
  
    const handleCommentSubmit = async () =>{
      if (newComment.trim()) {
        const response = await fetch(`${API_BASE_URL}/add-comment?postId=${postId}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newComment })
        });
        const newCommentObject = await response.json();
        setComments([...comments, newCommentObject]);
        setNewComment('');
      }
    }
  
    const handleLoveClick = async () => {
      const response = await fetch(`${API_BASE_URL}/add-love?postId=${postId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      setLoves(data.loves);
    };
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900 p-8">
       {/* <header className="relative w-full h-64"> */}
        {/* Cover Picture */}
        {/* <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${coverImage})`,
          }}
        ></div> */}
         {/* <div className="relative z-10 flex items-center justify-center h-full bg-black bg-opacity-50">
          <div className="text-center text-white">
            <h1 className="text-4xl font-bold">Comments and Love</h1>
            <p className="text-sm mt-2">By Aleena Alby | Dec 14, 2024</p>
          </div>
        </div> */}
        {/* <h1 className="text-4xl font-bold text-red-500">Facebook Photo Counter</h1>
        <p className="text-sm text-gray-500 mt-2">By Aleena Alby | Nov 1, 2024</p> */}
      {/* </header> */}

      {/* Introduction Section */}
      <section className="mt-8 max-w-4xl mx-auto">
      <h3 className="text-2xl font-semibold text-gray-900">Overview</h3>
        <p className="text-base leading-relaxed">
            Allows users to interact with a post by leaving anonymous
            comments and expressing their appreciation through a love button. 
        </p>
        
        <div className="mt-8">
          <h6 className="text-xl font-semibold text-gray-900">Comments</h6>
          <p className="mt-2 text-base leading-relaxed">
            Users can enter their comments in a text area, and the comments will be displayed to 
            all visitors without any identifying information about the author.
          </p>
          <p className="mt-4 text-base leading-relaxed">To implement the anonymous comments feature, the application:</p>
          <ul className="list-disc list-inside mt-4">
            <li>Saves the user's comment text in the Cloudflare Workers KV store, associated with the specific post ID.</li>
            <li>Retrieves the list of comments for the post from the KV store and displays them on the page, showing the comment text and a timestamp, but no user information.</li>
            <li>Provides a form for users to submit new comments, which are then added to the list of comments in the KV store.</li>
        </ul>
        </div>
        <div className="mt-8">
          <h6 className="text-xl font-semibold text-gray-900">Love</h6>
          <p className="mt-2 text-base leading-relaxed">
          The number of loves for the post is displayed next to the heart icon.
          </p>
          <p className="mt-4 text-base leading-relaxed">To implement the anonymous comments feature, the application:</p>
          <ul className="list-disc list-inside mt-4">
            <li>Stores the current love count for the post in the Cloudflare Workers KV store, associated with the specific post ID.</li>
            <li>Increments the love count when the user clicks the heart button, updating the value in the KV store.</li>
            <li>Retrieves the current love count from the KV store and displays it next to the heart icon.</li>
        </ul>
        </div>

      
      </section>

      {/* Love Button Section */}
      <section className="max-w-4xl mx-auto mt-8 flex items-center justify-center space-x-4">
        <button
          onClick={handleLoveClick}
          className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-red-50 transition-colors focus:outline-none"
        >
          <Heart
            className={`w-6 h-6 ${loves > 0 ? 'text-red-500 fill-current' : 'text-gray-500'}`}
          />
          <span>{loves}</span>
        </button>
      </section>

      <section className="max-w-4xl mx-auto mt-8 bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Leave an Anonymous Comment</h2>
      <textarea
          placeholder="Share your thoughts anonymously..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="w-full mb-4 p-3 border border-gray-300 rounded-lg resize-none focus:ring-2 focus:ring-blue-200"
          rows={4}
        />
      <button
      onClick={handleCommentSubmit}
        className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Submit Comment
        </button>

         {/* Comments Display */}
         {comments.length > 0 && (
          <div className="mt-6">
            <h3 className="text-xl font-medium mb-4 text-gray-700">Recent Comments</h3>
            {comments.map((comment) => (
              <div
                key={comment.id}
                className="bg-gray-50 p-4 rounded-lg mb-3 border border-gray-200"
              >
                <p className="text-gray-800">{comment.text}</p>
                <p className="text-xs text-gray-500 mt-2">
                  Anonymous • {comment.timestamp}
                </p>
              </div>
            ))}
          </div>
        )}
      </section>




    </div>
  );
};

export default AnonymousFeatureDocumentation;
