import React from "react";

const TravelDiary = () => {
  const photos = [
    "path/to/photo1.jpg",
    "path/to/photo2.jpg",
    "path/to/photo3.jpg",
    // Add more image paths here
  ];

  return (
    <div className="p-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {photos.map((photo, index) => (
          <div key={index} className="w-full h-64">
            <img
              src={photo}
              alt={`Travel Diary ${index + 1}`}
              className="w-full h-full object-cover rounded shadow"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TravelDiary;
