import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="bg-white w-full sm:w-48 sm:h-full sm:fixed top-0 left-0 border sm:border-none sm:border-r border-gray-200">
      {/* Color Bar */}
      {/* <div className="px-4 py-2">
        <div className="flex">
          <div className="h-1 flex-grow bg-red-600"></div>
          <div className="h-1 flex-grow bg-yellow-400"></div>
          <div className="h-1 flex-grow bg-gray-600"></div>
          <div className="h-1 flex-grow bg-gray-800"></div>
          <div className="h-1 flex-grow bg-black"></div>
        </div>
      </div> */}

      {/* Clickable Name Section */}
      <div className="p-4 text-center sm:text-right sm:pr-4">
        <Link
          to="/"
          className="text-2xl font-bold text-gray-800 block"
        >
          Aleena
        </Link>
        <Link
          to="/"
          className="text-2xl font-bold text-gray-800 block"
        >
          Alby
        </Link>
      </div>

      {/* Navigation Links */}
      <ul className="mt-4 space-y-4 text-center sm:text-right sm:pr-4">
        <li>
          <Link to="/photos/vista" className="text-gray-700 hover:text-red-500">
            Vista
          </Link>
        </li>
        <li>
          <Link to="/photos/travel_diary" className="text-gray-700 hover:text-red-500">
            Travel Diary
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
